import * as React from "react";
import favicon from "../images/favico.ico";
import { Helmet } from "react-helmet";
import parse from 'html-react-parser';
import "./intervention.css";
import Layout from "../layouts/layout";
import { Link } from "gatsby";

const BlogSiglePage = (props) => {
    const { content } = props.pageContext;
    const { title } = props.pageContext;
    const { titleMeta } = props.pageContext;
    const { metaDesc } = props.pageContext;
    const { featuredImage } = props.pageContext;
    const { childs } = props.pageContext;
    const { uri } = props.pageContext;

    return (
        <Layout>
            <Helmet htmlAttributes={{
                lang: 'fr-FR',
            }}>
                <link rel="icon" type="image/x-icon" href={favicon} />
                <title>{titleMeta}</title>
                <meta name="description" content={metaDesc} />
                <meta name="robots" content="index, follow" />
                <link data-react-helmet="true" rel="canonical" href={"https://www.medica-tour.fr" + uri}></link>
                <meta name="msvalidate.01" content="BF6180D2B9843FD051D8456A095B9C38" />
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-10925153812"></script>
    <script>
      {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'AW-10925153812');
      `}
    </script>
            </Helmet>
            <div className="container-fluid pt-5 pb-5" style={{
                backgroundImage: `url(${featuredImage && featuredImage.sourceUrl})`,
                backgroundSize: "cover",
                objectFit: "contain",
                color: "white",
                backgroundColor: "gray",
                backgroundPosition: "center",
            }}>
                <div className="container intern-page-style">
                    <h1 className="service-title">
                        {title && parse(title)}
                    </h1>
                </div>
            </div>

            <div className="container mt-4">
                <div className="row">

                    {!childs ||
                        childs.map((child, i) => {
                            return (
                                <div className="col-sm-12 col-md-3 pt-2">
                                    <div className="card h-100" >
                                        <img src={child.featuredImage.sourceUrl} className="card-img-top img-fluid" alt={child.title} />
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        })

                    }

                </div>
            </div>


            <div className="container-fluid pt-2 blog-single">
                <div className="container ">
                    <div className="row">
                        {content && parse(content)}
                    </div>
                </div>
            </div>
        </Layout>
    )
}
export default BlogSiglePage